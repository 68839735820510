// .input
//     .MuiSelect-root
//         padding: 18.5px 14px !important
//     input
//         color: black !important
//         padding: 18.5px 14px !important
//         height: 3px
//     label
//         transform: translate(14px, -6px) scale(0.75) !important
//         padding: 0 5px
//         background: white !important
// .MuiAutocomplete-inputRoot
//     padding: 0px !important

.MuiPagination-ul
    margin: 0
    display: flex
    padding: 0
    flex-wrap: inherit
    list-style: none
    align-items: center
.data-table
    svg
        cursor: pointer
    .MuiTableCell-root
        padding: 4px 4px!important
    th,td
        font-size: 13px!important
        min-width: 8rem !important
.containerMain
    max-width: 500px
    min-height: 100vh
    margin: 0px auto
    border: 1px solid #dddddd
    border-radius: 5px
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
.dialogBottomPkg
    .MuiDialog-paperScrollPaper
        border-radius: 15px 15px 0px 0px
        bottom: 0px
        margin: 0px
        position: absolute
.dialogContent
    min-width: 500px
    padding: 30px

.content
    margin: 0px auto
    max-width: 500px
    height: 100vh
    width: 100%
    background: #F5F5F5
    border: 1px solid #dddddd
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    overflow: hidden
.accPkgContainer
    padding:30px
    padding-top:10px
    // background: #fff
    // border-radius: 10px 10px 0px 0px
    padding-bottom: 20px!important
    // box-shadow: 0px -1px 14px -1px rgba(0,0,0,0.30)
    height: 100%
    width: 100%
    overflow: auto
.pkgCard
    // width:100%
    padding:10px
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset
    margin: 15px
    border-radius: 10px
    background: #fff
#packages .secondaryContainer
    background: #fff
    border-radius: 10px 10px 0 0
    box-shadow: 0 -1px 14px -1px rgba(0,0,0,.3)
    height: 100%
    margin-top: 20px!important
    overflow: auto
    padding: 30px
    width: 100%

@media (max-width: 500px) 
  .dialogContent
    min-width: 100vw



