.p14
  font-size: 14px!important
.topLabel
  background: #fff
  margin:-15px 0px 0px 10px
  font-size: 14px
  display: block
  width:75px
  color:#7B7C7E
.borderBox1
  border:1px solid #eeeeee
  border-radius:8px
  width: inherit
  margin-bottom:5px
  padding:5px
  cursor: pointer
@media (max-width: 500px) 
  .dialogContent
    min-width: 100vw



