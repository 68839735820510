// .input
//     .MuiSelect-root
//         padding: 18.5px 14px !important
//     input
//         color: black !important
//         padding: 18.5px 14px !important
//         height: 3px
//     label
//         transform: translate(14px, -6px) scale(0.75) !important
//         padding: 0 5px
//         background: white !important
// .MuiAutocomplete-inputRoot
//     padding: 0px !important
.MuiTimelineContent-root
    display: grid
    align-items: center
.reportEmail
    background: #fff
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    border-start-start-radius: 10px
    border-start-end-radius: 10px
    margin-top: 15px
    padding-bottom: 15px
.gradiant2
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    border-radius: 6px
    padding-bottom: 30px
    background: linear-gradient(90deg, rgba(204, 149, 192, 0.1) 0%, rgba(219, 212, 180, 0.1) 50%, rgba(122, 161, 210, 0.1) 100%)
.gradiant1
    background: linear-gradient(90deg, rgba(201, 255, 191, 0.2) 0%, rgba(255, 175, 189, 0.2) 100%)
    label
        padding:0px 5px!important
    .MuiFormGroup-root
        display: block 
.content2
    margin: 0px auto
    max-width: 500px
    height: 100vh
    width: 100%
    background: #F5F5F5
    border: 1px solid #dddddd
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    overflow: auto
.content2 p
    color: #7B7C7E
.content2 b
    color: #191919
.content2 .MuiTimelineDot-root
    background: #fff
    box-shadow: none
    margin: 0px
.MuiPagination-ul
    margin: 0
    display: flex
    padding: 0
    flex-wrap: inherit
    list-style: none
    align-items: center
.data-table
    svg
        cursor: pointer
    .MuiTableCell-root
        padding: 4px 4px!important
    th,td
        font-size: 13px!important
        min-width: 8rem !important
.containerMain
    max-width: 500px
    min-height: 100vh
    margin: 0px auto
    border: 1px solid #dddddd
    border-radius: 5px
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
.dialogBottom
    .MuiDialog-paperScrollPaper
        border-radius: 15px 15px 0px 0px
        bottom: 0px
        margin: 0px
        position: absolute
.dialogContent
    min-width: 500px
    padding: 30px
.dialogContent2
    max-width: 500px
    overflow: auto
#collectionSlot
    margin: 0px auto
    max-width: 500px
    height: 100vh
    width: 100%
    border: 1px solid #dddddd
    overflow: auto
#collectionSlot .secondaryContainer
    padding:30px
    margin-top: 0px!important
    box-shadow: none
    height: 100%
    width: 100%
    overflow: auto
.datePro
    text-align: center
    padding:5px 15px
    color:#fff
    background: #E06358
    border-radius: 10px
.datePro p 
    margin: 3px 0px
    font-weight: 700
.dateLite
    text-align: center
    padding:5px 15px
    color:#E06358
    background: #fff
    border-radius: 10px
    border: 1px solid #E06358
.dateLite p 
    margin: 3px 0px
    font-weight: 700

@media (max-width: 500px) 
  .dialogContent
    min-width: 100vw
  .dialogContent2
    max-width: 100vw
    overflow: auto



